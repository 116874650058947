import { render, staticRenderFns } from "./Cookie.vue?vue&type=template&id=a4143dee&scoped=true&"
import script from "./Cookie.vue?vue&type=script&lang=js&"
export * from "./Cookie.vue?vue&type=script&lang=js&"
import style0 from "./Cookie.vue?vue&type=style&index=0&id=a4143dee&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4143dee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/elements/Button.vue').default})
