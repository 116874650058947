export const SanityContent = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-content.js' /* webpackChunkName: "components/sanity-content" */).then(c => wrapFunctional(c.default || c))
export const SanityFile = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-file.js' /* webpackChunkName: "components/sanity-file" */).then(c => wrapFunctional(c.default || c))
export const SanityImage = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-image.js' /* webpackChunkName: "components/sanity-image" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn = () => import('../../components/elements/Animations/CustomFadeIn.vue' /* webpackChunkName: "components/animation-custom-fade-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFlagIn = () => import('../../components/elements/Animations/CustomFlagIn.vue' /* webpackChunkName: "components/animation-custom-flag-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapFadeInExample = () => import('../../components/elements/Animations/GsapFadeIn_Example.vue' /* webpackChunkName: "components/animation-gsap-fade-in-example" */).then(c => wrapFunctional(c.default || c))
export const DocumentErrorPage = () => import('../../components/document/ErrorPage.vue' /* webpackChunkName: "components/document-error-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentPage = () => import('../../components/document/Page.vue' /* webpackChunkName: "components/document-page" */).then(c => wrapFunctional(c.default || c))
export const PageCookie = () => import('../../components/page/Cookie.vue' /* webpackChunkName: "components/page-cookie" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/Footer.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/page/Loader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const PageScrollTop = () => import('../../components/page/ScrollTop.vue' /* webpackChunkName: "components/page-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const PageTransitionElements = () => import('../../components/page/TransitionElements.vue' /* webpackChunkName: "components/page-transition-elements" */).then(c => wrapFunctional(c.default || c))
export const BookingTool = () => import('../../components/content/BookingTool.vue' /* webpackChunkName: "components/booking-tool" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/content/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const DetailPageHero = () => import('../../components/content/DetailPageHero.vue' /* webpackChunkName: "components/detail-page-hero" */).then(c => wrapFunctional(c.default || c))
export const DetailPageHeroMenus = () => import('../../components/content/DetailPageHeroMenus.vue' /* webpackChunkName: "components/detail-page-hero-menus" */).then(c => wrapFunctional(c.default || c))
export const EventList = () => import('../../components/content/EventList.vue' /* webpackChunkName: "components/event-list" */).then(c => wrapFunctional(c.default || c))
export const ExampleWithPicture = () => import('../../components/content/ExampleWithPicture.vue' /* webpackChunkName: "components/example-with-picture" */).then(c => wrapFunctional(c.default || c))
export const FileDownload = () => import('../../components/content/FileDownload.vue' /* webpackChunkName: "components/file-download" */).then(c => wrapFunctional(c.default || c))
export const MediaList = () => import('../../components/content/MediaList.vue' /* webpackChunkName: "components/media-list" */).then(c => wrapFunctional(c.default || c))
export const Multimedia = () => import('../../components/content/Multimedia.vue' /* webpackChunkName: "components/multimedia" */).then(c => wrapFunctional(c.default || c))
export const Slideshow = () => import('../../components/content/Slideshow.vue' /* webpackChunkName: "components/slideshow" */).then(c => wrapFunctional(c.default || c))
export const Teasers = () => import('../../components/content/Teasers.vue' /* webpackChunkName: "components/teasers" */).then(c => wrapFunctional(c.default || c))
export const TextBlock = () => import('../../components/content/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => wrapFunctional(c.default || c))
export const TypographySetup = () => import('../../components/content/TypographySetup.vue' /* webpackChunkName: "components/typography-setup" */).then(c => wrapFunctional(c.default || c))
export const VideoHero = () => import('../../components/content/VideoHero.vue' /* webpackChunkName: "components/video-hero" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/content/YoutubeVideo.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const FormHoneypotForm = () => import('../../components/content/Form/HoneypotForm.vue' /* webpackChunkName: "components/form-honeypot-form" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/content/Form/index.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const BookingBtns = () => import('../../components/elements/BookingBtns.vue' /* webpackChunkName: "components/booking-btns" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const FieldDatePicker = () => import('../../components/elements/FieldDatePicker.vue' /* webpackChunkName: "components/field-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FieldPhoneNumber = () => import('../../components/elements/FieldPhoneNumber.vue' /* webpackChunkName: "components/field-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/elements/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitch = () => import('../../components/elements/LanguageSwitch.vue' /* webpackChunkName: "components/language-switch" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/elements/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const MenuOverlay = () => import('../../components/elements/MenuOverlay.vue' /* webpackChunkName: "components/menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/elements/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationTree = () => import('../../components/elements/NavigationTree.vue' /* webpackChunkName: "components/navigation-tree" */).then(c => wrapFunctional(c.default || c))
export const Picture = () => import('../../components/elements/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/elements/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/elements/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SocialMedia = () => import('../../components/elements/SocialMedia.vue' /* webpackChunkName: "components/social-media" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/elements/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/elements/AccordionGroup/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionGroup = () => import('../../components/elements/AccordionGroup/index.vue' /* webpackChunkName: "components/accordion-group" */).then(c => wrapFunctional(c.default || c))
export const ModalContent = () => import('../../components/elements/Modal/ModalContent.vue' /* webpackChunkName: "components/modal-content" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/elements/Modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
